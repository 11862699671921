import classNames from "classnames";
import { Link } from "react-router-dom";
import { MATCHUPS_OPTIONS } from "@ugg/shared/query-params/filter-options/champions/matchups";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";
import YellowAlert from "@ugg/shared/components/common/YellowAlert";
import { getChampionBuildUrl } from "@ugg/shared/routes/app-routes";
import { MatchupsInfo } from "@ugg/shared/api/data-parser/champions/matchups";

type ListType = "best-win-rate" | "worst-win-rate" | "gold-diff";

interface CounterListViewProps {
  className?: string;
  championId: number;
  listCount: number;
  incrementListCount: (num: number) => void;
  filters: any;
  data: MatchupsInfo["counters"];
  isLowSampleSize: boolean;
  listType: ListType;
}

export function CountersListView(props: CounterListViewProps) {
  const { getChampionName, getNormalizedChampionName, getChampionImgFromSprite } = getRiotAssetsContext();
  const { className, listCount, incrementListCount, championId, filters, data, isLowSampleSize, listType } = props;
  const championName = getChampionName(championId);
  const formattedData = formatData(data, championName, filters, listType);

  return (
    <div className={classNames("rounded-[3px] overflow-hidden", className)}>
      <div className="p-[18px] bg-purple-400">
        <HeaderBullet className="flex items-center justify-between mb-[18px]">
          <div>{formattedData.header}</div>
          {isLowSampleSize && <YellowAlert label="Low sample size" />}
        </HeaderBullet>
        <div className="flex items-center font-normal text-[10px] leading-[16px] text-accent-gray-900">
          <div className="flex items-center justify-center mr-[14px] ml-[2px] flex-none w-[24px]">
            <img src={formattedData.icon} />
          </div>
          <span className="line-clamp-3">{formattedData.snippet}</span>
        </div>
      </div>
      <div className="bg-purple-400 max-h-[600px] max-sm:max-h-[500px] overflow-auto">
        {formattedData.data.map((champion, index) => {
          const { champion_id, matches, color } = champion;
          const championName = getChampionName(champion_id);
          const normalizedChampionName = getNormalizedChampionName(champion_id);
          const percent = formattedData.percentFn(champion, formattedData.data);
          const label = formattedData.labelFn(champion);

          return (
            <Link
              key={champion_id}
              to={getChampionBuildUrl(normalizedChampionName)}
              className={classNames("flex items-center p-[12px] w-full border-t-[1px] border-purple-500", {
                "bg-[rgb(50,115,250)] hover:bg-[rgb(100,149,251)]": listType === "best-win-rate" && index <= 2,
                "bg-[rgb(255,78,80)] hover:bg-[rgb(255,129,130)]": listType === "worst-win-rate" && index <= 2,
                "bg-[rgb(49,219,158)] hover:bg-[rgb(92,227,178)]": listType === "gold-diff" && index <= 2,
                "bg-opacity-20 hover:bg-opacity-20": index === 0,
                "bg-opacity-[0.15] hover:bg-opacity-[0.15]": index === 1,
                "bg-opacity-10 hover:bg-opacity-10": index === 2,
              })}
            >
              <div
                className={`
                relative mr-[8px] w-[36px] h-[36px] rounded-[2px] overflow-hidden
                [&>div]:absolute [&>div]:top-[50%] [&>div]:left-[50%] 
                [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%] [&>div]:scale-[1.1]`}
              >
                {getChampionImgFromSprite(champion_id, { size: 36 })}
              </div>
              <div className="flex-1 pr-[24px] overflow-hidden">
                <div className="text-white text-[14px] font-bold truncate">{championName}</div>
                <div className="mt-[6px] mb-[3px] h-[6px] rounded-[3px] bg-purple-100 overflow-hidden max-w-[124px]">
                  <div
                    className="h-full bg-accent-gray-100"
                    style={{
                      width: `${percent}%`,
                      background: color,
                    }}
                  />
                </div>
              </div>
              <div>
                <div
                  className={classNames("text-[12px] font-bold leading-[15px] whitespace-nowrap text-right", {
                    "text-accent-blue-400": listType === "best-win-rate",
                    "text-accent-orange-500": listType === "worst-win-rate",
                    "text-[#31db9e]": listType === "gold-diff",
                  })}
                >
                  {label}
                </div>
                <div className="mt-[2px] text-accent-gray-100 text-[11px] font-normal whitespace-nowrap">
                  {numberWithCommas(matches)} games
                </div>
              </div>
            </Link>
          );
        })}
        {/* {listCount < formattedData.data.length && (
          <div
            className="flex items-center justify-center w-full py-[16px] border-t-[1px] border-purple-500 bg-purple-100 font-semibold text-[12px] cursor-pointer hover:opacity-80"
            onClick={() => incrementListCount(formattedData.data.length)}
          >
            See More Champions
          </div>
        )} */}
      </div>
    </div>
  );
}

const blueGradient = [
  "rgba(50, 115, 250, 1)",
  "linear-gradient(0deg, rgba(50, 115, 250, 0.8), rgba(50, 115, 250, 0.8)), #FFFFFF",
  "linear-gradient(0deg, rgba(50, 115, 250, 0.6), rgba(50, 115, 250, 0.6)), #FFFFFF",
  "linear-gradient(0deg, rgba(50, 115, 250, 0.4), rgba(50, 115, 250, 0.4)), #FFFFFF",
  "linear-gradient(0deg, rgba(50, 115, 250, 0.2), rgba(50, 115, 250, 0.2)), #FFFFFF",
];
const redGradient = [
  "rgba(255, 78, 80, 1)",
  "linear-gradient(0deg, rgba(255, 78, 80, 0.8), rgba(255, 78, 80, 0.8)), #FFFFFF",
  "linear-gradient(0deg, rgba(255, 78, 80, 0.6), rgba(255, 78, 80, 0.6)), #FFFFFF",
  "linear-gradient(0deg, rgba(255, 78, 80, 0.4), rgba(255, 78, 80, 0.4)), #FFFFFF",
  "linear-gradient(0deg, rgba(255, 78, 80, 0.2), rgba(255, 78, 80, 0.2)), #FFFFFF",
];
const greenGradient = [
  "rgba(49, 219, 158, 1)",
  "linear-gradient(0deg, rgba(49, 219, 158, 0.8), rgba(49, 219, 158, 0.8)), #FFFFFF",
  "linear-gradient(0deg, rgba(49, 219, 158, 0.6), rgba(49, 219, 158, 0.6)), #FFFFFF",
  "linear-gradient(0deg, rgba(49, 219, 158, 0.4), rgba(49, 219, 158, 0.4)), #FFFFFF",
  "linear-gradient(0deg, rgba(49, 219, 158, 0.2), rgba(49, 219, 158, 0.2)), #FFFFFF",
];

const formatData = (data: MatchupsInfo["counters"], championName: string, filters: any, listType: ListType) => {
  const currentRank = MATCHUPS_OPTIONS.rank.find((rank) => rank.value === filters.rank);
  const currentRegion = MATCHUPS_OPTIONS.region.find((region) => region.value === filters.region);
  const newData = data.map((x) => {
    const newChampion = { ...x };
    newChampion.win_rate = Math.round((100 - newChampion.win_rate) * 100) / 100;
    newChampion.gold_adv_15 = -1 * newChampion.gold_adv_15;
    return newChampion;
  });

  type SnippetType<T extends string | number | symbol> = Record<
    T,
    {
      header: string;
      icon: string;
      snippet: string;
      percentFn: (value: SnippetType<T>[T]["data"][number], data: SnippetType<T>[T]["data"]) => number;
      labelFn: (value: SnippetType<T>[T]["data"][number]) => string;
      data: Array<(typeof data)[number] & { color?: string }>;
    }
  >;
  type SnippetListType = SnippetType<ListType>;

  const snippet: SnippetListType = {
    "best-win-rate": {
      header: `Best Picks vs ${championName}`,
      icon: "https://static.bigbrain.gg/assets/ugg/icons/blue-bg-check.svg",
      snippet: `These picks are strong against ${championName} at many stages of the game. Champs listed by highest win rate matchups vs ${championName} in ${currentRegion?.label} ${currentRank?.label}.`,
      percentFn: (value, data) => value.win_rate,
      labelFn: (value) => `${value.win_rate}% WR`,
      data: [...newData]
        .sort((a, b) => b.win_rate - a.win_rate)
        .map((x) => {
          const obj: (typeof data)[number] & { color?: string } = { ...x };
          if (obj.win_rate >= 55) {
            obj.color = blueGradient[0];
          } else if (obj.win_rate >= 53) {
            obj.color = blueGradient[1];
          } else if (obj.win_rate >= 51.5) {
            obj.color = blueGradient[2];
          } else if (obj.win_rate >= 48.5) {
            obj.color = blueGradient[3];
          } else {
            obj.color = blueGradient[4];
          }
          return obj;
        }),
    },
    "worst-win-rate": {
      header: `Worst Picks vs ${championName}`,
      icon: "https://static.bigbrain.gg/assets/ugg/icons/red-skull.svg",
      snippet: `These picks are weak against ${championName} at many stages of the game. Champs listed by lowest win rate matchups vs ${championName} in ${currentRegion?.label} ${currentRank?.label}.`,
      percentFn: (value, data) => value.win_rate,
      labelFn: (value) => `${value.win_rate}% WR`,
      data: [...newData]
        .sort((a, b) => a.win_rate - b.win_rate)
        .map((x) => {
          const obj: (typeof data)[number] & { color?: string } = { ...x };
          if (obj.win_rate < 45) {
            obj.color = redGradient[0];
          } else if (obj.win_rate <= 47) {
            obj.color = redGradient[1];
          } else if (obj.win_rate <= 48.5) {
            obj.color = redGradient[2];
          } else if (obj.win_rate <= 50) {
            obj.color = redGradient[3];
          } else {
            obj.color = redGradient[4];
          }
          return obj;
        }),
    },
    "gold-diff": {
      header: `Best Lane Counters vs ${championName}`,
      icon: "https://static.bigbrain.gg/assets/ugg/icons/green-lanes.svg",
      snippet: `These picks counter ${championName} during early game laning phase. Highest gold differential at 15 (GD@15) vs ${championName} in ${currentRegion?.label} ${currentRank?.label}.`,
      percentFn: (value, data) => {
        const { gold_adv_15 } = value;
        const min = Math.abs(data[data.length - 1].gold_adv_15);
        const base = Math.abs(data[0].gold_adv_15);
        const buffer = (base + min) * 0.05;
        const max = base + min + buffer;
        return ((gold_adv_15 + min + buffer) / max) * 100;
      },
      labelFn: (value) => `${value.gold_adv_15 > 0 ? "+" : ""}${value.gold_adv_15} GD15`,
      data: [...newData]
        .sort((a, b) => b.gold_adv_15 - a.gold_adv_15)
        .map((x, index) => {
          const obj: (typeof data)[number] & { color?: string } = { ...x };
          if (obj.gold_adv_15 <= 0) {
            obj.color = greenGradient[4];
          } else if (index <= 0) {
            obj.color = greenGradient[0];
          } else if (index <= 2) {
            obj.color = greenGradient[1];
          } else if (index <= 5) {
            obj.color = greenGradient[2];
          } else if (index <= 9) {
            obj.color = greenGradient[3];
          } else {
            obj.color = greenGradient[4];
          }
          return obj;
        }),
    },
  };

  return snippet[listType];
};
