import { useJSONFetcher, JSONFetcherOptions } from "@outplayed/json-fetcher";
import { useUGGApiVersions } from "../ugg-api-versions";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params/valid-params";
import { getApiRoot } from "@ugg/shared/api/api-helpers";
import { cleanMatchups } from "@ugg/shared/api/data-parser/champions/matchups";
import { CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";

import { Matchups } from "@ugg/shared/interfaces/champions/matchups.interface";

export function useMatchupsURL(championId: number, options: { queueType?: string; patch?: string }) {
  const { queueType, patch } = options;
  const { data: version } = useUGGApiVersions({ apiKey: "matchups", patch });
  const root = getApiRoot(patch);

  return [`${root}/matchups`, patch, queueType, championId, `${version}.json`].join("/");
}

// Counters and Matchups Page
export function useChampionMatchups(
  championId: number,
  options?: { params?: Record<string, any> } & JSONFetcherOptions<Matchups, ReturnType<typeof cleanMatchups>>,
) {
  const { params = {}, ssr = false, skip = false } = options || {};
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(CHAMPION_PAGES.MATCHUPS, params, true);

  const championMatchupsURL = useMatchupsURL(championId, validatedQueryParams);
  return useJSONFetcher<Matchups, ReturnType<typeof cleanMatchups>>(championMatchupsURL, {
    ssr,
    skip,
    onCompleted: (url, json) => cleanMatchups(json) || {},
  });
}
