import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useChampionRankingStats } from "@ugg/shared/api/requests/champions/ranking-stats";
import { getRankings } from "@ugg/shared/api/data-parser/champions/ranking-stats";
import { getMatchups } from "@ugg/shared/api/data-parser/champions/matchups";
import { useChampionMatchups } from "@ugg/shared/api/requests/champions/matchups";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { validParams } from "@ugg/shared/query-params//valid-params";
import { normalizeRole } from "@ugg/shared/utils/role-helpers";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { CountersListView } from "./CountersListView";
import { ChampionProfilePagePlaceholder } from "@ugg/shared/components/Champions/ChampionProfilePagePlaceholder";
import { ChampionProfilePageError } from "@ugg/shared/components/Champions/ChampionProfilePageError";
import { CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";

export function CountersContainer() {
  const location = useLocation();
  const [listCount, setListCount] = useState(10);
  const { championId, role } = useChampionProfileContext();

  const incrementListCount = (totalChampCount: number) => {
    setListCount(totalChampCount);
  };

  useEffect(() => {
    setListCount(10);
  }, [championId, location.search]);

  const {
    data: rankingStats,
    loading: fetchingRankingStats,
    error: errorRankingStats,
  } = useChampionRankingStats(championId, { ssr: true });

  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams(validParams);
  const validatedQueryParams = validateQueryParams(
    CHAMPION_PAGES.COUNTERS,
    { ...queryParams, role: normalizeRole(role || "") },
    true,
  );

  const {
    data: matchups,
    loading: fetchingMatchups,
    error: errorMatchups,
  } = useChampionMatchups(championId, { params: validatedQueryParams, ssr: true });
  let filteredMatchups = getMatchups(matchups, validatedQueryParams)?.counters || [];
  filteredMatchups = filteredMatchups.filter((champion) => champion.pick_rate >= 0.5);

  const filteredRankingStats = getRankings(rankingStats, validatedQueryParams);
  const isLowSampleSize =
    filteredRankingStats && (filteredRankingStats.pick_rate < 1 || filteredRankingStats.total_matches < 100);

  const commonProps = {
    championId,
    listCount,
    incrementListCount,
    filters: validatedQueryParams,
    data: filteredMatchups,
    isLowSampleSize: !!isLowSampleSize,
  };

  const isLoading = fetchingMatchups || fetchingRankingStats;

  if (isLoading) {
    return <ChampionProfilePagePlaceholder className="w-full" />;
  } else if (filteredMatchups.length === 0) {
    return <ChampionProfilePageError className="w-full" />;
  }

  return (
    <div className="w-full grid gap-[12px] grid-cols-[repeat(3,1fr)] max-[900px]:grid-cols-[repeat(2,1fr)] max-[600px]:grid-cols-1">
      <CountersListView {...commonProps} listType="best-win-rate" />
      <CountersListView {...commonProps} listType="worst-win-rate" />
      <CountersListView {...commonProps} listType="gold-diff" />
    </div>
  );
}
